import { replaceInnerHtmlByEl } from './ce_workers';
import nurtureEntrances from './ce_nurture_entrances';

const populateSequenceOptionsForCompany = async(
    serviceType,
    projectId
) => {

    const params = window.convertEngine.getQueryStringParams();

    const allowAdminSequences = params.m === "1"

    let options = (nurtureEntrances?.[serviceType] || [])
    .filter(x => allowAdminSequences ? true : !x.admin);

    // TODO: Retrieve company sequence options
    console.log('ce:', projectId)

    if (!options) {
        console.warn('WARN: No nurture options found for service type:', serviceType);
        return null;
    };

    replaceInnerHtmlByEl(
        document.getElementById('cc-nurture-action-description'),
        options[0]?.description
    );

    const optionsSelect = document.getElementById('cc-nurture-action')
    // add internal/management options
    optionsSelect.options[0]?.remove();
    options.forEach(option => {
        const l = optionsSelect.options?.length
        optionsSelect.options[l] = new Option(option.title, option.action)
        return null;
    })

    optionsSelect.addEventListener('change', e => {
        const val = e.target.value;

        const optionForVal = options.find(x => x.action === val);

        if (!optionForVal) {
            console.warn('WARN: No nurture option was found for value:', val);
            return null;
        };

        if (optionForVal.one_time_notification) {
            document.getElementById('cc-otn-disclaimer').classList.remove('hide');
            document.getElementById('cc-reminder-configs').classList.add('hide');
        } else {
            document.getElementById('cc-otn-disclaimer').classList.add('hide');
            document.getElementById('cc-reminder-configs').classList.remove('hide');
        }

        replaceInnerHtmlByEl(
            document.getElementById('cc-nurture-action-description'),
            optionForVal.description
        );
        return null;
    })

    return null;
}

const sequenceExportFunctions = {
    populateSequenceOptionsForCompany
};

export default sequenceExportFunctions;