import { populateSequenceOptionsForCompany } from './ce_sequences'
// const triggerSequenceForLeads = async(action) => {

// }

const handleSequencePromptWorkflowAction = async(
  action
) => {
  let sequencePromptModal = new window.bootstrap.Modal(document.getElementById('ce_modal-wf_action-sequence_prompt'), {
    // keyboard: false
  })

  await populateSequenceOptionsForCompany(
    'car_finance_lead',
    'any'
  )

  console.log(action, sequencePromptModal, "MODAL");
  sequencePromptModal.show()
}

const handleWorkflowAction = async(action) => {
  const type = action.type;
  switch(type) {
    case 'sequence_prompt': {
      console.log('SEQUENCE PROMPT HERE')
      await handleSequencePromptWorkflowAction(action);
      break;
    };
    default: {
      console.info('CE: Action is not supported in UI:', type);
    }
  };

  return null;
}

const workflowActionExportFunctions ={
  handleWorkflowAction,
  handleSequencePromptWorkflowAction
};
export default workflowActionExportFunctions;