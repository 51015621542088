class ConvertEngineEvents { // event listener system
    constructor(){
      this.events = {};
    }
    on(
      event, // event name
      fn // callback fn
    ){
      if (typeof event !== 'string') return null;
      const parsedEvents = event.split(',');
      parsedEvents.forEach(e => {
        const trimmedEvent = e.trim();
        if (!this.events[trimmedEvent]) this.events[trimmedEvent] = new Set();
        this.events[trimmedEvent].add(fn);
      })
      return null;
    }
    emit(
      event, // event name
      args // arguments to emit
    ){
    //   let self = this;
      console.info('CE: Emitting event:', event, args)
      this.events[event] && this.events[event].forEach((fn) => {
        fn.apply(this, [args]);
      })
      return this;
    }
  };

  export default ConvertEngineEvents;