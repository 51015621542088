// -------------- STRING DECLARATIONS --------------
const nurtureEntrances = {
    car_finance_lead: [
      {
        title: "🚗 Car Finance: 🔥 Nurture sequence, 5 days",
        action: "retarget_agressive-start",
        description: "Aggressive <i>(in time, not tone)</i> nurture sequence, will contact the Lead daily for 4 days, with a breakup on day 5.",
        one_time_notification: false
      },
      {
        title: "🚗 Car Finance: ❄️ Nurture sequence, 5 weeks",
        action: "retarget_moderate-start",
        description: "Moderate nurture sequence, will contact the Lead weekly for 4 weeks, with a breakup on week 5.",
        one_time_notification: false
      },
      {
        title: "🚗 Car Finance: 🐌 Nurture sequence, 5 months",
        action: "retarget_conservative-start",
        description: "Conservative nurture sequence, will contact the Lead monthly for 4 months, with a breakup on month 5.",
        one_time_notification: false
      },
      {
        title: "🚗 Car Finance: 📆 App Taken - OTN",
        action: "otn_action_application_taken",
        description: "Will send an immediate email to the customer from us to celebrate the Lead taking the next step (progressing to application). 🎉",
        one_time_notification: true
      },
      {
        title: "🚗 Car Finance: 📆 Appointment No-show retarget",
        action: "carfl_appointment-retarget_noshow_a_1",
        description: "Initiating a sequence to retarget a Lead who does not show up to an appointment. 📆",
        one_time_notification: false
      },
      {
        title: "🚗 Car Finance: 👋 Not interested: Re-engage",
        action: "carfl_retarget-not_interested_a_1",
        description: "Use to re-engage a prospect who decided to not proceed with financing. Designed to warm up the prospect and understand if their circumstances have changed, or now is a good time. <b>NOTE: A minimum 3 MONTH delay is recommended for this sequence, as the language is designed to re-engage a prospect after some delay since your last interaction.</b>",
        one_time_notification: false,
        default_reminder: {
          unit: 4,
          period: "months"
        }
      },
      {
        title: "🚗 Car Finance: 🥶 Gone cold: Re-engage",
        action: "carfl_retarget-app_taken_a_1",
        description: "Use to re-engage a prospect who indicated they were interested in financing, however now appear to have gone cold/unresponsive. If the sequence ends without a response from the Lead, it will automatically push them into the 'Not interested: Re-engage' sequence",
        one_time_notification: false
      },
      {
        title: "🚗 Car Finance: 🤖⚡️ ConvertGPT - Cold outreach",
        action: "carfl-chatgpt_prompt-car_finance_retarget_1",
        description: "Initiating a sequence for cold outreach. Our AI will intelligently aim to warm up the prospect, and pass them to a human when the customer is ready.",
        one_time_notification: false,
        admin: true
      }
    ],
    "car_finance_lead-TESTER": [
        {
          title: "🔥 Nurture sequence, 5 days",
          action: "retarget_agressive-start",
          description: "Aggressive <i>(in time, not tone)</i> nurture sequence, will contact the Lead daily for 4 days, with a breakup on day 5.",
          one_time_notification: false
        },
        {
          title: "❄️ Nurture sequence, 5 weeks",
          action: "retarget_moderate-start",
          description: "Moderate nurture sequence, will contact the Lead weekly for 4 weeks, with a breakup on week 5.",
          one_time_notification: false
        },
        {
          title: "🐌 Nurture sequence, 5 months",
          action: "retarget_conservative-start",
          description: "Conservative nurture sequence, will contact the Lead monthly for 4 months, with a breakup on month 5.",
          one_time_notification: false
        },
        {
          title: "📆 App Taken - OTN",
          action: "otn_action_application_taken",
          description: "Will send an immediate email to the customer from us to celebrate the Lead taking the next step (progressing to application). 🎉",
          one_time_notification: true
        },
        {
          title: "📆 Appointment No-show retarget",
          action: "carfl_appointment-retarget_noshow_a_1",
          description: "Initiating a sequence to retarget a Lead who does not show up to an appointment. 📆",
          one_time_notification: false
        },
        {
          title: "🤖⚡️ ConvertGPT - Cold outreach",
          action: "carfl-chatgpt_prompt-car_finance_retarget_1",
          description: "Initiating a sequence for cold outreach. Our AI will intelligently aim to warm up the prospect, and pass them to a human when the customer is ready.",
          one_time_notification: false,
          admin: true
        }
    ],
    "ce_backfill_lead": [
        {
          title: "🚗 Car Finance: 🔥 Nurture sequence, 5 days",
          action: "retarget_agressive-start",
          description: "Aggressive <i>(in time, not tone)</i> nurture sequence, will contact the Lead daily for 4 days, with a breakup on day 5.",
          one_time_notification: false
        },
        {
          title: "🚗 Car Finance: ❄️ Nurture sequence, 5 weeks",
          action: "retarget_moderate-start",
          description: "Moderate nurture sequence, will contact the Lead weekly for 4 weeks, with a breakup on week 5.",
          one_time_notification: false
        },
        {
          title: "🚗 Car Finance: 🐌 Nurture sequence, 5 months",
          action: "retarget_conservative-start",
          description: "Conservative nurture sequence, will contact the Lead monthly for 4 months, with a breakup on month 5.",
          one_time_notification: false
        },
        {
          title: "🚗 Car Finance: 📆 App Taken - OTN",
          action: "otn_action_application_taken",
          description: "Will send an immediate email to the customer from us to celebrate the Lead taking the next step (progressing to application). 🎉",
          one_time_notification: true
        },
        {
          title: "🚗 Car Finance: 📆 Appointment No-show retarget",
          action: "carfl_appointment-retarget_noshow_a_1",
          description: "Initiating a sequence to retarget a Lead who does not show up to an appointment. 📆",
          one_time_notification: false
        },
        {
          title: "🚗 Car Finance: 👋 Not interested: Re-engage",
          action: "carfl_retarget-not_interested_a_1",
          description: "Use to re-engage a prospect who decided to not proceed with financing. Designed to warm up the prospect and understand if their circumstances have changed, or now is a good time. <b>NOTE: A minimum 3 MONTH delay is recommended for this sequence, as the language is designed to re-engage a prospect after some delay since your last interaction.</b>",
          one_time_notification: false,
          default_reminder: {
            unit: 4,
            period: "months"
          }
        },
        {
          title: "🚗 Car Finance: 🥶 Gone cold: Re-engage",
          action: "carfl_retarget-app_taken_a_1",
          description: "Use to re-engage a prospect who indicated they were interested in financing, however now appear to have gone cold/unresponsive.",
          one_time_notification: false
        },
        {
          title: "🚗 Car Finance: 🤖⚡️ ConvertGPT - Cold outreach",
          action: "carfl-chatgpt_prompt-car_finance_retarget_1",
          description: "Initiating a sequence for cold outreach. Our AI will intelligently aim to warm up the prospect, and pass them to a human when the customer is ready.",
          one_time_notification: false,
          admin: true
        }
      ],
      ce_custom_lead: [
        {
          title: "🚗 Car Finance: 📩 Lead In",
          action: "carfl_init_1",
          description: "ConvertGPT will attempt to call the customer to connect them to a Broker, or alternatively drive them towards booking a time for a call back.",
          one_time_notification: false
        },
        {
          title: "🚗 Car Finance: 🔥 Nurture sequence, 5 days",
          action: "retarget_agressive-start",
          description: "Aggressive <i>(in time, not tone)</i> nurture sequence, will contact the Lead daily for 4 days, with a breakup on day 5.",
          one_time_notification: false
        },
        {
          title: "🚗 Car Finance: ❄️ Nurture sequence, 5 weeks",
          action: "retarget_moderate-start",
          description: "Moderate nurture sequence, will contact the Lead weekly for 4 weeks, with a breakup on week 5.",
          one_time_notification: false
        },
        {
          title: "🚗 Car Finance: 🐌 Nurture sequence, 5 months",
          action: "retarget_conservative-start",
          description: "Conservative nurture sequence, will contact the Lead monthly for 4 months, with a breakup on month 5.",
          one_time_notification: false
        },
        {
          title: "🚗 Car Finance: 📝 App Taken - OTN",
          action: "otn_action_application_taken",
          description: "Will send an immediate email to the customer from us to celebrate the Lead taking the next step (progressing to application). 🎉",
          one_time_notification: true
        },
        {
          title: "🚗 Car Finance: 💨 Booking No-show retarget",
          action: "carfl_appointment-retarget_noshow_a_1",
          description: "Initiating a sequence to retarget a Lead who does not show up to an appointment. 📆",
          one_time_notification: false
        },
        {
          title: "🚗 Car Finance: 👋 Not interested: Re-engage",
          action: "carfl_retarget-not_interested_a_1",
          description: "Use to re-engage a prospect who decided to not proceed with financing. Designed to warm up the prospect and understand if their circumstances have changed, or now is a good time. <b>NOTE: A minimum 3 MONTH delay is recommended for this sequence, as the language is designed to re-engage a prospect after some delay since your last interaction.</b>",
          one_time_notification: false,
          default_reminder: {
            unit: 4,
            period: "months"
          }
        },
        {
          title: "🚗 Car Finance: 🥶 Gone cold: Re-engage",
          action: "carfl_retarget-app_taken_a_1",
          description: "Use to re-engage a prospect who indicated they were interested in financing, however now appear to have gone cold/unresponsive. If the sequence ends without a response from the Lead, it will automatically push them into the 'Not interested: Re-engage' sequence",
          one_time_notification: false
        },
        {
          title: "🚗 Car Finance: 🤖⚡️ ConvertGPT - Cold outreach",
          action: "carfl-chatgpt_prompt-car_finance_retarget_1",
          description: "Initiating a sequence for cold outreach. Our AI will intelligently aim to warm up the prospect, and pass them to a human when the customer is ready.",
          one_time_notification: false,
          admin: true
        }
      ],
      "car_finance_lead-BRAIFI": [
        {
          title: "🚗 Car Finance: 🔥 Nurture sequence, 5 days",
          action: "retarget_agressive-start",
          description: "Aggressive <i>(in time, not tone)</i> nurture sequence, will contact the Lead daily for 4 days, with a breakup on day 5.",
          one_time_notification: false
        },
        {
          title: "🚗 Car Finance: ❄️ Nurture sequence, 5 weeks",
          action: "retarget_moderate-start",
          description: "Moderate nurture sequence, will contact the Lead weekly for 4 weeks, with a breakup on week 5.",
          one_time_notification: false
        },
        {
          title: "🚗 Car Finance: 🐌 Nurture sequence, 5 months",
          action: "retarget_conservative-start",
          description: "Conservative nurture sequence, will contact the Lead monthly for 4 months, with a breakup on month 5.",
          one_time_notification: false
        },
        {
          title: "🚗 Car Finance: 📆 App Taken - OTN",
          action: "otn_action_application_taken",
          description: "Will send an immediate email to the customer from us to celebrate the Lead taking the next step (progressing to application). 🎉",
          one_time_notification: true
        },
        {
          title: "🚗 Car Finance: 📆 Booking No-show retarget",
          action: "carfl_appointment-retarget_noshow_a_1",
          description: "Initiating a sequence to retarget a Lead who does not show up to an appointment. 📆",
          one_time_notification: false
        },
        {
          title: "🚗 Car Finance: 👋 Not interested: Re-engage",
          action: "carfl_retarget-not_interested_a_1",
          description: "Use to re-engage a prospect who decided to not proceed with financing. Designed to warm up the prospect and understand if their circumstances have changed, or now is a good time. <b>NOTE: A minimum 3 MONTH delay is recommended for this sequence, as the language is designed to re-engage a prospect after some delay since your last interaction.</b>",
          one_time_notification: false,
          default_reminder: {
            unit: 4,
            period: "months"
          }
        },
        {
          title: "🚗 Car Finance: 🥶 Gone cold: Re-engage",
          action: "carfl_retarget-app_taken_a_1",
          description: "Use to re-engage a prospect who indicated they were interested in financing, however now appear to have gone cold/unresponsive. If the sequence ends without a response from the Lead, it will automatically push them into the 'Not interested: Re-engage' sequence",
          one_time_notification: false
        },
        {
          title: "🚗 Car Finance: 🤖⚡️ ConvertGPT - Cold outreach",
          action: "carfl-chatgpt_prompt-car_finance_retarget_1",
          description: "Initiating a sequence for cold outreach. Our AI will intelligently aim to warm up the prospect, and pass them to a human when the customer is ready.",
          one_time_notification: false,
          admin: true
        }
      ],
  };
// ------------ END NUTRUTE SEQUENCES ---------------

export default nurtureEntrances