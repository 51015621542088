import { replaceInnerHtmlByEl } from './ce_workers';

const renderElementsForPlugin = () => {
    const pagepath = window.location.pathname;

    const {
        name, // lead name
        // email, // lead email
        // phone // lead phone
    } = window.convertEngine.getQueryStringParams();

    if (pagepath === "/app/ext/no_lead_found.html") {
        const parsedName = name === "null" ?  null : name;
        document.getElementById('ext_import_lead_btn').innerText = `Import  ${parsedName || 'Lead'} to Convert Engine`;
    }

    return null;
};renderElementsForPlugin;

const updateChromeExtensionUrl = (
    url,
    args = {}
) => {
    const {
        platform, // CRM unique identifier
        id, // user ID
        name, // lead name
        email, // lead email
        phone // lead phone
    } = window.convertEngine.getQueryStringParams();

    const qspObj = Object.assign(
        { ext: "chrome" },
        { platform, id, name, email, phone },
        args
    );

    const qsp = Object.keys(qspObj).map(key => key + '=' + (qspObj[key] || "")).join('&');

    const formedUrl = url + "?" + qsp;
    return window.location.replace(formedUrl);
    // console.info(qsp, qspObj, formedUrl)
    // return null;
}

const renderPossibleLeadMatches = (
    matches
) => {
    const container = document.getElementById('locate-lead-container');
    if (!container) {
        console.warn('CE:WARN - No lead locator container found, returning');
        return null;
    };

    const leadHtml = matches.map(lead => `
        <div class="card mb-2 ext_possible_match_container">
            <div class="card-header pb-0">
                <span><b>${lead.data?.data?.name}</b></span>
            </div>
            <div class="card-body pt-1 row">
                <div class="col-10">
                    <span>${lead.data?.data?.phone} | ${lead.data?.data?.email}</span>
                </div>
                <div class="col-2">
                    <button 
                        class="btn btn-sm btn-primary" 
                        data-id="${lead.data.id}"
                        data-project_id="${lead.data.project_id}"
                        onclick="handleMatchLeadConfirm(this)">🔗</button>
                </div>
            </div>
        </div>
    `).join("");

    const fullHtml = `
    <div class="card mb-3">
        <div class="card-header">
            <h5 class="mb-0">ℹ️ ${matches.length} possible matches found</h5>
        </div>
    </div>
    ${leadHtml}
    `

    replaceInnerHtmlByEl(
        container,
        fullHtml
    );

    return null;
}
const renderAllLeadMatches = (
    matches
) => {
    const container = document.getElementById('locate-lead-container');
    if (!container) {
        console.warn('CE:WARN - No lead locator container found, returning');
        return null;
    };
    console.log(matches, 'matches')
    const leadHtml = matches.map(lead => `
        <a href="/app/ext/profile.html?i=${lead.id}&p=${lead.project_id}">
            <div class="button btn card mb-2 ext_possible_match_container">
                <div class="card-body row">
                    <div class="col-10">
                        <h5><b>${lead.data?.name}</b></h5>
                        <span>${lead.data?.phone} | ${lead.data?.email}</span>
                    </div>
                    <div class="col-2">
                        <button 
                            class="btn btn-sm btn-primary">🔗 View</button>
                    </div>
                </div>
            </div>
        </a>
    `).join("");

    const fullHtml = `
    <div class="card mb-3">
        <div class="card-header">
            <h5 class="mb-0">ℹ️ ${matches.length} matching CRM records found</h5>
            <span class="fs--1">We've found more than 1 CRM record with this ID, if this shouldn't happen, let us know.</span>
        </div>
    </div>
    ${leadHtml}
    `

    replaceInnerHtmlByEl(
        container,
        fullHtml
    );

    return null;
}

const handleMatchLeadConfirm = el => {

    const container = el.closest('.ext_possible_match_container');

    const leadId = el.getAttribute('data-id');
    const projectId = el.getAttribute('data-project_id');

    const {
        name, // lead name
    } = window.convertEngine.getQueryStringParams();

    const footer = container.querySelector('.card-footer')
    if (footer) {
        console.log(footer, "FOOTER")
        footer.remove();
    }

    const footerHtml = `
        <div class="card-footer bg-dark text-light">
            <span>Link this Lead to your CRM record (${name})?</span>
            <button 
                class="btn btn-warning"
                data-id="${leadId}"
                data-project_id="${projectId}"
                onclick="handleMatchLeadRecordLinkFromExtension(this)"
            >🔗 Link record</button>
        </div>    
    `;

    replaceInnerHtmlByEl(
        container,
        footerHtml,
        true
    )

    return null;
};handleMatchLeadConfirm;
const handleMatchLeadRecordLinkFromExtension = async(
    el,
    data = {},
    handle = false
) => {
    const {
        platform, // CRM unique identifier
        id, // user ID
    } = window.convertEngine.getQueryStringParams();

    const leadId = data.id || el.getAttribute('data-id');
    const projectId = data.project_id || el.getAttribute('data-project_id');

    console.info('CE: Linking CRM Records:', { platform, id, leadId, projectId})

    const res = await window.convertEngine.createLeadCrmRecordLink(
        projectId,
        {
            lead_id: leadId,
            crm_record: {
                platform,
                id
            }
        }
    )

    console.info(res, "RES IS HERE ON RECORD LINK")

    // if handle is true, we want to handle this elsewhere
    if (handle) return null;
    
    window.location.reload();

    return null;
};

const prepToLinkExistngLead = async(
    el
) => {
    const {
        platform, // CRM unique identifier
        id, // user ID
    } = window.convertEngine.getQueryStringParams();
    
    const inputEl = document.getElementById('ext_existing_lead_match_url');

    if (!inputEl) return null;

    const url = inputEl.value;

    const params = url?.split('?')[1]; // split to query strings only

    if (!params) {
        window.convertEngine.createToastAlert(
            'Invalid URL',
            'URL is invalid, make sure to copy the whole URL from the address bar. e.g. "https://app.convertengine.com.au/app/leads/profile.html?i=123456&p=ABCDEF"',
            'danger'
        )
        return null;
    }

    const builtParams = JSON.parse('{"' + decodeURI(params).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')

    console.log({platform, id}, el, builtParams, "HERE");

    await handleMatchLeadRecordLinkFromExtension(
        null,
        {
            id: builtParams.i,
            project_id: builtParams.p
        },
        true
    );

    return updateChromeExtensionUrl(
        '/app/ext/locate.html'
    );
};prepToLinkExistngLead;

const populatePluginLeadImportFields = () => {
    const {
        platform, // CRM unique identifier
        id, // user ID
        name, // lead name
        email, // lead email
        phone // lead phone
    } = window.convertEngine.getQueryStringParams();

    const trimmedPhone = (phone || "").replace(/[^0-9.]/g,"").trim()

    // if record link info is here, add it, otherwise remove it
    if (!platform && !id) {
        document.getElementById('crm_record_link_platform').remove()    
        document.getElementById('crm_record_link_id').remove()
    } else {
        document.getElementById('crm_record_link_platform').value = platform
        document.getElementById('crm_record_link_id').value = id

    }
    document.getElementById('name').value = name || ""
    document.getElementById('email').value = email || ""
    document.getElementById('phone').value = trimmedPhone

    return null;
};populatePluginLeadImportFields;

const initPluginUserSearch = async() => {
    const params = window.convertEngine.getQueryStringParams();

    const {
        platform, // CRM unique identifier
        id, // user ID
        name, // lead name
        email, // lead email
        phone // lead phone
    } = params; 

    console.log(platform, id, name, email, phone, "PARAMS");

    // if there is an ID that we could use to lookup, look through them
    if (platform && id) {

        const companies = window.convertEngine.user?.associations?.map(x => x.company_id)?.filter(x => x !== "*");
        console.log(companies)

        const foundRecords = await Promise.all(
            companies.map(
                async (company) => window.convertEngine.searchForLeadLink(company, { platform, id })
            )
        );

        console.log(foundRecords, "RECORDS ARE FOUND")

        const flattenedFoundRecords = foundRecords.map(
            record => record?.isOk ? record.val?.res : null
        ).flat().filter(x => x !== null);

        console.log(flattenedFoundRecords, "RECORDS")

        
        if (!flattenedFoundRecords?.length) { // if no records found...
            console.info('CE: No record found!')
            
            const projects = window.convertEngine.retrieveProjectsFromAssociations();
            const trimmedPhone = phone?.replace(/[^0-9.]/g,"").trim() || ""

            console.info('ce: TRIMMED PHONE', trimmedPhone)

            const searchRes = await Promise.all(
                projects.map(
                    async(project) => trimmedPhone.length ? 
                        window.convertEngine.searchQueryForProject(project, trimmedPhone) : 
                        null
                )
            )

            console.log(searchRes, "SEARCH RES IS HERE")

            const flattenedSearchRes = searchRes.map(
                search => search?.isOk ? search.val?.res : null
            ).flat().filter(x => x !== null);

            console.log(flattenedSearchRes.length, "SEARCH RES")

            // TODO: If search res.length, do something with that.
            if (flattenedSearchRes?.length) {
                return renderPossibleLeadMatches(flattenedSearchRes)
            }

            return updateChromeExtensionUrl(
                '/app/ext/no_lead_found.html'
            );
        } else if (flattenedFoundRecords?.length > 1) { // else if >1 records found...
            console.info('CE: More than one record found!')
            // const records = flattenedFoundRecords.map(record => record.id);
            return renderAllLeadMatches(flattenedFoundRecords);
        } else { // otherwise, there is a record!
            console.info('CE: One record found!');
            const linkedLead = flattenedFoundRecords?.[0];
            return updateChromeExtensionUrl(
                '/app/ext/profile.html',
                { i: linkedLead.id, p: linkedLead.project_id }
            );
        }

    }

    return null;
};initPluginUserSearch;