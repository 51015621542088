import { replaceInnerHtmlByEl } from './ce_workers';

// form submission handlers


const addFieldToAutomationScenario = (
    type,
    container,
    emoji = null
) => {


    let html = `
        <div class="input-group automation-scenario-field-group mb-3">
            ${emoji ? `<span class="input-group-text">${emoji}</span>` : ''}
            <input class="form-control automation-scenario-field" type="text" placeholder="${type}" data-automation-scenario-field="${type}">
            <button class="input-group-text btn btn-secondary" onclick="removeFieldFromAutomationScenario(this)">❌</button>
        </div>
    `;

    console.log(html);


    const el = document.createElement('div');

    el.className = "input-group automation-scenario-field-group mb-3";

    // Emoji start
    if (emoji) {
        const emojiSpan = document.createElement('span');
        emojiSpan.className = "input-group-text"
        emojiSpan.innerText = emoji;
        el.append(emojiSpan);
    } 
    // Field input
    const fieldInput = document.createElement('input');
    fieldInput.className = "form-control automation-scenario-field"
    fieldInput.type = "text";
    fieldInput.placeholder = type;
    fieldInput.setAttribute('data-automation-scenario-field', type);
    // Remove button
    const removeButton = document.createElement('button');
    removeButton.className = "input-group-text btn btn-secondary";
    removeButton.setAttribute("onclick", "removeFieldFromAutomationScenario(this)");
    removeButton.innerText = "❌";
    el.append(
        fieldInput,
        removeButton
    )

    const containerEl = document.getElementById(container);
    if (!containerEl) {
        console.warn('CE:ERR - Cannot append new field to scenario, container does not exist:', container, containerEl);
        return null;
    };

    containerEl.append(el);

    return null;

};addFieldToAutomationScenario;

const removeFieldFromAutomationScenario = (
    el
) => {
    console.info('CE: Removing field from automation scenario:', el)
    const fieldGroup = el.closest('.automation-scenario-field-group');
    console.info(fieldGroup);
    fieldGroup.remove()
}; removeFieldFromAutomationScenario;

const createAutomationScenarioQualification = async(
    el
) => {

    const companyId = window.convertEngine.user?.associations?.[0]?.company_id;
    // title
    const title = document.getElementById('ce_automation_scenario_title')?.value
    // description
    const description = document.getElementById('ce_automation_scenario_description')?.value
    // persona
    const persona = document.getElementById('ce_automation_scenario_persona')?.value
    // personality
    const personality = document.getElementById('ce_automation_scenario_personality')?.value
    // company_name
    const companyName = document.getElementById('ce_automation_scenario_company_name')?.value
    // industry
    const industry = document.getElementById('ce_automation_scenario_industry')?.value

    // questions
    const questionEls = document.querySelectorAll('[data-automation-scenario-field="question"]');
    const questions = Array.from(questionEls || []).map(x => x?.value).filter(x => x !== null);

    // rules
    const ruleEls = document.querySelectorAll('[data-automation-scenario-field="rule"]');
    const rules = Array.from(ruleEls || []).map(x => x?.value).filter(x => x !== null);

    // faqs
    const faqEls = document.querySelectorAll('[data-automation-scenario-field="faq"]');
    const faqs = Array.from(faqEls || []).map(x => x?.value).filter(x => x !== null);

    // completion_action
    const completionAction = document.getElementById('ce_automation_scenario_completion_action')?.value

    // scenario_channel
    const scenarioChannel = document.getElementById('ce_automation_scenario_channel')?.value

    // followup_structure
    const followupStructure = document.getElementById('ce_automation_scenario_followup_structure')?.value

    // create the request object
    let request = {
        company_id: companyId,
        type: "qualification",
        fields: {
            title,
            persona,
            personality,
            company_name: companyName,
            industry,
            questions,
            rules,
            faqs,
            completion_action: completionAction,
            scenario_channel: scenarioChannel,
            followup: followupStructure
        }
    };

    if (description?.length) request.fields.description = description;

    console.log(request, "REQUEST")


    window.convertEngine.handleActionButtonState(el, 'automation_scenario', 'start');

    const scenarioCreateRes = await window.convertEngine.apiQuery(
        `company/${companyId}/engine/create-automation-scenarios`,
        'POST',
        request
    );

    console.log(scenarioCreateRes, "CREATE RES HERE")

    if (scenarioCreateRes.isErr) {
        console.warn('CE:ERR - An error occured when attempting to create new Scenario:', scenarioCreateRes);
        window.convertEngine.createToastAlert(
            '🛑 Scenario could not be created', 
            scenarioCreateRes.val,
            "danger")
        window.convertEngine.handleActionButtonState(el, 'automation_scenario', 'fail')
    } else {
        window.convertEngine.createToastAlert(
            '😁 Success!', 
            'Your new automation was started successfully! 🤖'
        )
        window.convertEngine.handleActionButtonState(el, 'automation_scenario', 'success')
    }

    // const activityRes = await window.convertEngine.addInteractionActivityTolead(
    //     leadId,
    //     projectId,
    //     { note: activityNote, type: activityType }
    // );

    // if (activityRes.isErr) {
    //     console.warn('CE:ERR - An error occured when adding activity:', activityRes);
    //     window.convertEngine.createToastAlert(
    //         '🤔 Uh oh!', 
    //         "We weren't able to create the Scenario. If this persists please let us know",
    //         "danger")
    //     window.convertEngine.handleActionButtonState(el, 'automation_scenario', 'fail')
    // } else {
    //     window.convertEngine.createToastAlert(
    //         '😁 Success!', 
    //         'Scenario created successfully! 🎉 You can now access this '
    //     )
    //     window.convertEngine.handleActionButtonState(el, 'automation_scenario', 'success')
    //     setTimeout(() => {
    //         return window.location.reload();
    //     }, 3500)
    // }


    return null;
};createAutomationScenarioQualification;

class SequenceScenarioBuilder {
    constructor() {
        this.state = {};
    }
    retrieveSessionStateForScenarioSequence() {
        let scenario;

        const baseScenario = {
            title: null,
            description: null,
            steps: [],
            sequence_config: {
                on_optout: {
                    type: "do_nothing"
                },
                on_complete: {
                    type: "scenario",
                    action: null
                }
            }
        }
    
        const contextScenario = this.state.ce_scenario_sequence;
        if (contextScenario?.sequence_config) return contextScenario;
    
        try {
            scenario = JSON.parse(sessionStorage.getItem('ce_scenario_sequence'));
            if (scenario === null || !scenario || !scenario.sequence_config) {
                console.info('Scenario is null, adding base');
                scenario = baseScenario;
            }
        } catch(err) {
            scenario = baseScenario
        };
    
        this.state.ce_scenario_sequence = scenario;
    
        return scenario;
    }
    updateSessionStateForScenarioSequence (
        scenario
    ) {

        this.state.ce_scenario_sequence = scenario;

        sessionStorage.setItem('ce_scenario_sequence', JSON.stringify(scenario));
    
        console.info('CE: Scenario Sequence session state updated.')
        return scenario;
    }
    removeSessionStateForScenarioSequence () {
        delete this.state.ce_scenario_sequence;

        sessionStorage.removeItem('ce_scenario_sequence');

        console.info('CE: Scenario Sequence session state removed')
        return this.reloadAutomationScenarioSequenceUi();
    }
    handleClearSequenceConfiguration () {
        console.info('Seq: resetting all configuration');
        this.removeSessionStateForScenarioSequence();

        this.prefillGptAssistantFields();
        this.prefillSequenceConfigFields();

        return null;
    }
    addAutomationScenarioStepToLocalContext (index = null) {
        const scenario = this.retrieveSessionStateForScenarioSequence();

        const baseStep = {
            actions: []
        }
    
        console.info(scenario, index, "SCENARIO IS HERE")
        
        if (index !== null) { // is a splice at a certain position
            scenario.steps.splice(index+1, 0, baseStep)
        } else { // is an append to the end
            scenario.steps.push(baseStep)
        }
    
        this.updateSessionStateForScenarioSequence(scenario);
    
        return scenario;
    }
    renderScenarioSequenceStepActions (
        actions
    ) {
        this;
        const addActionToolbar = `
            <span>Add action: </span>
            <button class="btn btn-primary btn-sm" onclick="retrieveSequenceScenarioBuilder().addActionToScenarioSequenceStep(this, 'email')">📩 Email</button>
            <button class="btn btn-primary btn-sm" onclick="retrieveSequenceScenarioBuilder().addActionToScenarioSequenceStep(this, 'sms')">📲 SMS</button>
            <button class="btn btn-primary btn-sm disabled hide" disabled="disabled">📞 Call (coming soon!)</button>
        `;

        if (!actions?.length) {
            return addActionToolbar;
        };
    
        const renderEmailAction = (action, index) => `
        <div>
            <span>📧 Email:</span>
            <button class="btn btn-danger float-right fs--1 mb-1 ce_scenario_delete_action_btn" onclick="retrieveSequenceScenarioBuilder().removeActionFromScenarioStep(this, ${index})">
                <span class="fas fa-trash-alt text-white"></span>
            </button>
        </div>
        <input class="form-control" placeholder="Subject" data-ce_step_action="email:subject" value="${action.content?.subject || ""}" placeholder="Subject goes here">
        <div class="ce_automation_scenario_textarea pell"></div>
        <textarea class="form-control ce_automation_scenario_textarea hide" rows="12" placeholder="Write your email here" data-ce_step_action="email:content">${action.content?.content || ""}</textarea>`
    
        const renderSmsAction = (action, index) => `
        <div>
            <span>📲 SMS:</span>
            <button class="btn btn-danger float-right fs--1 mb-1 ce_scenario_delete_action_btn" onclick="retrieveSequenceScenarioBuilder().removeActionFromScenarioStep(this, ${index})">
                <span class="fas fa-trash-alt text-white"></span>
            </button>
        </div>
        <textarea class="form-control" rows="3" placeholder="Write your SMS here" data-ce_step_action="sms:content">${action.content?.content || ""}</textarea>`
    
        const actionRenderMap = {
            email: renderEmailAction,
            sms: renderSmsAction
        }
    
        const mappedActions = actions.map(
            (action, index) => `<div class="ce_automation_scenario_step_action my-2" data-ce_action_index="${index}" data-ce_action_type="${action.type}">
                ${actionRenderMap[action.type](action, index)}
            </div>`
        ).join("");
    
        return `<div class="border-bottom pb-2 mb-2 ce_scenario_step_add_action_container">${addActionToolbar}</div>${mappedActions}`;
    }
    renderScenarioSequenceStep (
        step,
        index,
        scenario
    ) {
        const steps = scenario.steps;

        console.log(scenario, steps)
    
        const reminderConfigFooter = (reminder) => {
            const html = `
                <div class="ce_automation_scenario_step_hr border-bottom border-secondary text-center bg-light my-3 m-auto position-relative">
                    <span class="px-2">⏳ Wait <b>${reminder.unit} ${reminder.period}</b></span>
                    <div class="position-absolute" style="top:0px;right:-75px;">
                        <button class="btn btn-subtle-primary btn-sm" onclick="retrieveSequenceScenarioBuilder().addStepToAutomationSequenceScenario(${index})">
                            <span class="text-900 fas fa-plus-square mx-2"></span>
                        </button>
                    </div>
                </div>`;
            return html;
        };
        const completeConfigFooter = (reminder) => {
            const onCompleteConfig = scenario.sequence_config?.on_complete || { type: 'scenario', action: null };
            let innerHtml;
            switch(onCompleteConfig.type) {
                case 'scenario': {
                    const scenarioName = this.state.scenario_options?.find(
                        x => x.action === onCompleteConfig.action
                    )?.title || null;
                    innerHtml = `🏁 Wait <b>${reminder.unit} ${reminder.period}</b>, then send to Scenario <b>${scenarioName || "..."}</b>`
                    break;
                };
                case 'notify_email': {
                    innerHtml = `🏁 Notify team via Email that this has completed. 📩`
                    break;
                };
                default: {
                    // do nothing!
                    innerHtml = `🏁 Scenario complete.`
                }
            }
            const html = `<div class="ce_automation_scenario_step_hr border-bottom border-secondary text-center bg-light my-3 m-auto"><span class="px-2">${innerHtml}</span></div>`;
            return html;
        };
    
        const isFinalStep = (index + 1) === steps.length ? true : false;
    
        const baseReminder = {
            unit: 1,
            period: 'days'
        }
    
        const reminder = step.reminder || baseReminder;
    
        const standardNext = () => {
            const html = `
            <div class="row">
                <div class="col-4">
                    <span>Then wait: </span>
                </div>
                <div class="col-4">
                    <input class="form-control" type="number" min=0 max=100 step=1 value="${reminder.unit || 1}" data-ce_step_action="reminder:unit">
                </div>
                <div class="col-4">
                    <select class="form-control" data-ce_step_action="reminder:period" value="${reminder.period || "days"}">
                        <option value="minutes" ${reminder.period === "minutes" ? "selected": ""}>Minutes</option>
                        <option value="hours" ${reminder.period === "hours" ? "selected": ""}>Hours</option>
                        <option value="days"  ${(reminder.period === "days" || !reminder.period) ? "selected": ""}>Days</option>
                        <option value="weeks" ${reminder.period === "weeks" ? "selected": ""}>Weeks</option>
                        <option value="months" ${reminder.period === "months" ? "selected": ""}>Months</option>
                    </select>
                </div>
            </div>
            
            <hr class="">
            

            <div class="row">
                <div class="col-4">
                    <div class="form-check form-switch">
                        <input class="form-check-input" id="reminder_force_nextbusinessday_${index}" type="checkbox" data-ce_step_action="reminder:force_nextbusinessday" ${reminder.config?.force_business_day ? "checked" : ""}/>
                        <label class="form-check-label" for="reminder_force_nextbusinessday_${index}">Force business day (Mon-Fri)</label>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-check form-switch">
                        <input class="form-check-input" id="reminder_force_nextbusinesshour_${index}" type="checkbox" data-ce_step_action="reminder:force_nextbusinesshour" ${reminder.config?.force_business_hour ? "checked" : ""}/>
                        <label class="form-check-label" for="reminder_force_nextbusinesshour_${index}">Force business hour (8am-5pm)</label>
                    </div>
                </div>
                <div class="col-4">
                    <label class="form-control-label" for="reminder_force_timeofday_${index}">Send at time of day?</label>
                    <select class="form-control" data-ce_step_action="reminder:force_timeofday" id="reminder_force_timeofday_${index}">
                        <option value=null ${!reminder.config?.force_timeofday ? "selected": ""}>Any (default)</option>
                        <option value="morning" ${reminder.config?.force_timeofday === "morning" ? "selected": ""}>🌻 Morning</option>
                        <option value="afternoon"  ${reminder.config?.force_timeofday === "afternoon" ? "selected": ""}>🌞 Afternoon</option>
                        <option value="evening" ${reminder.config?.force_timeofday === "evening" ? "selected": ""}>✨ Evening</option>
                        <option value="night" ${reminder.config?.force_timeofday === "night" ? "selected": ""}>🌜 Night</option>
                    </select>
                </div>
            </div>`;
            return html;
        };
    
        const finalNext = () => {
            const onCompleteConfig = scenario.sequence_config?.on_complete || { type: 'scenario', action: null };
    
            const renderFinalNextActionsByType = (type) => {
                switch (type) {
                    case 'scenario': {
                        const scenarioOptions = this.state.scenario_options || [];
                        return `<div class="ce_automation_scenario_step_next_block scenario_step_wait_container d-flex">
                            <span>Wait </span>
                            <input class="form-control w-25 mx-2" type="number" min=0 max=100 step=1 value="${reminder.unit}" data-ce_step_action="reminder:unit">
                            <select class="form-control w-25 mx-2" data-ce_step_action="reminder:period">
                                <option value="minutes" ${reminder.period === "minutes" ? "selected": ""}>Minutes</option>
                                <option value="hours" ${reminder.period === "hours" ? "selected": ""}>Hours</option>
                                <option value="days"  ${reminder.period === "days" ? "selected": ""}>Days</option>
                                <option value="weeks" ${reminder.period === "weeks" ? "selected": ""}>Weeks</option>
                                <option value="months" ${reminder.period === "months" ? "selected": ""}>Months</option>
                            </select>
                        </div>
                        <div class="ce_automation_scenario_step_next_block scenario_step_wait_container d-flex">
                            <span>Then send to Scenario:</span>
                            <select class="form-control w-50" data-ce_step_action="on_complete:action">
                                <option value=null>--- SELECT SCENARIO ---</option>
                                ${scenarioOptions.filter(opt => opt.title?.length)?.map(option => `<option value="${option.action}" ${onCompleteConfig.action === option.action ? "selected" : ""}>${option.title || ""}</option>`)}
                            </select>
                        </div>
                        
                        <hr class="">
            

                        <div class="row">
                            <div class="col-4">
                                <div class="form-check form-switch">
                                    <input class="form-check-input" id="reminder_force_nextbusinessday_${index}" type="checkbox" data-ce_step_action="reminder:force_nextbusinessday" ${reminder.config?.force_business_day ? "checked" : ""}/>
                                    <label class="form-check-label" for="reminder_force_nextbusinessday_${index}">Force business day (Mon-Fri)</label>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-check form-switch">
                                    <input class="form-check-input" id="reminder_force_nextbusinesshour_${index}" type="checkbox" data-ce_step_action="reminder:force_nextbusinesshour" ${reminder.config?.force_business_hour ? "checked" : ""}/>
                                    <label class="form-check-label" for="reminder_force_nextbusinesshour_${index}">Force business hour (8am-5pm)</label>
                                </div>
                            </div>
                            <div class="col-4">
                                <label class="form-control-label" for="reminder_force_timeofday_${index}">Send at time of day?</label>
                                <select class="form-control" data-ce_step_action="reminder:force_timeofday" id="reminder_force_timeofday_${index}">
                                    <option value="" ${!reminder.config?.force_timeofday ? "selected": ""}>Any (default)</option>
                                    <option value="morning" ${reminder.config?.force_timeofday === "morning" ? "selected": ""}>🌻 Morning</option>
                                    <option value="afternoon"  ${reminder.config?.force_timeofday === "afternoon" ? "selected": ""}>🌞 Afternoon</option>
                                    <option value="evening" ${reminder.config?.force_timeofday === "evening" ? "selected": ""}>✨ Evening</option>
                                    <option value="night" ${reminder.config?.force_timeofday === "night" ? "selected": ""}>🌜 Night</option>
                                </select>
                            </div>
                        </div>`
                    };
                    default: {
                        return '';
                    };
                };
            }
    
            const html = `<span>When sequence is complete... </span>
                <select class="form-control" onchange="retrieveSequenceScenarioBuilder().updateScenarioSequenceFinalStepConfig(this)">
                    <option value="scenario" ${onCompleteConfig.type === "scenario" ? "selected" : ""}>⚡️ Send to Scenario</option>
                    <option value="do_nothing" ${onCompleteConfig.type === "do_nothing" ? "selected" : ""}>👍 Do nothing</option>
                    <option value="notify_email" ${onCompleteConfig.type === "notify_email" ? "selected" : ""}>📩 Send me an email</option>
                    <option value="call_connect"  ${onCompleteConfig.type === "call_connect" ? "selected" : ""} disabled="disabled">📞 Call me (coming soon!)</option>
                </select>
                <div class="ce_automation_scenario_step_next_controls">${renderFinalNextActionsByType(onCompleteConfig.type)}</div>`;
                return html;
        }
    
        const html = `
        <div class="ce_automation_scenario_step my-4" data-ce_step="${index}">

            <div class="row g-3">
                <div class="col-11 ce_automation_scenario_step_inner p-3 bg-white shadow-sm">
                    <div class="row g-3">
                        <div class="col-1 ce_automation_scenario_step_indicator_container">
                            <div class="ce_automation_scenario_step_indicator bg-secondary fw-bold text-light fs--1 text-center rounded py-1">
                                <span>#${index+1}</span>
                            </div>
                        </div>
                        <div class="col-11 ce_automation_scenario_step_action_select_container">
                            ${this.renderScenarioSequenceStepActions(step.actions)}
                        </div>
                        <hr class="my-3 mt-4 w-75 m-auto">
                    </div>
                    <div class="row g-3">
                        <div class="col-12">
                            <div class="ce_automation_scenario_step_next_block scenario_step_wait_container">
                                ${isFinalStep ? finalNext() : standardNext()}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-1 ce_automation_scenario_step_action_container d-inline-grid pt-1">
                    <button class="btn btn-danger w-100 mb-5" onclick="retrieveSequenceScenarioBuilder().removeStepFromAutomationSequenceScenario(this)">    
                        <span class="text-900 fas fa-trash-alt text-white"></span>
                    </button>    
                    <button class="btn btn-subtle-secondary w-100 mt-1 mb-2 py-4 ce_scenario_step_move_btn" onclick="retrieveSequenceScenarioBuilder().updateOrderOfScenarioSteps(-1, this)">    
                        <span class="text-900 fas fa-arrow-up"></span>
                    </button>
                    <button class="btn btn-subtle-secondary w-100 mb-2 py-4 ce_scenario_step_move_btn" onclick="retrieveSequenceScenarioBuilder().updateOrderOfScenarioSteps(1, this)">    
                        <span class="text-900 fas fa-arrow-down"></span>
                    </button>
                </div>
            </div>

            
            <div class="ce_automation_scenario_step_footer">
                ${isFinalStep ? completeConfigFooter(reminder) : reminderConfigFooter(reminder)}
            </div>
        </div>`
    
        return html
    }
    handleSequenceActionSmsUpdate (
        content, // text content
        stepIndex // step id
    ) {
        const scenario = this.retrieveSessionStateForScenarioSequence();

        try {
            scenario.steps[stepIndex].actions[scenario.steps[stepIndex].actions.findIndex(x => x.type === "sms")].content = { content };
        } catch(err) {
            console.warn('CE: Could not update the content on this SMS action', err.message)
        }
    
        this.updateSessionStateForScenarioSequence(scenario);
    
        return null;
    }
    handleSequenceActionEmailUpdate (
        type, // e.g. subject
        content, // content of field
        stepIndex // step ID
    ) {
        const scenario = this.retrieveSessionStateForScenarioSequence();

        const fieldMap = {
            subject: "subject",
            content: "content"
        }
    
        const field = fieldMap[type];
    
        try {
            scenario.steps[stepIndex].actions[scenario.steps[stepIndex].actions.findIndex(x => x.type === "email")].content[field] = content;
        } catch(err) {
            console.warn('CE: Could not update the content on this email action', err.message)
        }
    
        return this.updateSessionStateForScenarioSequence(scenario);
    };
    handleSequenceActionReminderUpdate (
        type,
        value,
        stepIndex
    ) {
        const scenario = this.retrieveSessionStateForScenarioSequence();

        const baseReminder = {
            unit: 1,
            period: "days"
        };
    
        // if no reminder set, we should set one
        try {
            scenario.steps[stepIndex].reminder = scenario.steps[stepIndex].reminder || baseReminder;
            scenario.steps[stepIndex].reminder[type] = value;
        } catch (err) {
            console.warn('CE: Could not update reminder field:', type, err.message);
        };
    
        this.updateSessionStateForScenarioSequence(scenario);
    
        return this.reloadAutomationScenarioSequenceUi();
    };
    handleSequenceActionReminderConfigUpdate (
        type,
        value,
        stepIndex
    ) {
        const scenario = this.retrieveSessionStateForScenarioSequence();
        console.log(type, value, "ALL IS HERE")
        const baseReminder = {
            unit: 1,
            period: "days",
            config: {}
        };
    
        // if no reminder set, we should set one
        try {
            scenario.steps[stepIndex].reminder = scenario.steps[stepIndex].reminder || baseReminder;
            if (!scenario.steps[stepIndex].reminder.config) {
                scenario.steps[stepIndex].reminder.config = {};
            }
            if (!value || (typeof value === "string" && !value.length)) {
                delete scenario.steps[stepIndex].reminder.config[type]
            } else {
                scenario.steps[stepIndex].reminder.config[type] = value;
            }
        } catch (err) {
            console.warn('CE: Could not update reminder field:', type, err.message);
        };
    
        this.updateSessionStateForScenarioSequence(scenario);
    
        return this.reloadAutomationScenarioSequenceUi();
    }
    handleSequenceActionOnCompleteUpdate (
        type,
        value,
        // stepIndex
    ) {

        const scenario = this.retrieveSessionStateForScenarioSequence();

        switch (type) {
            case 'scenario': {
                scenario.sequence_config.on_complete = {
                    type,
                    action: value
                };
                break;
            };
            default: {
                // do nothing;
                break;
            }
        }

        this.updateSessionStateForScenarioSequence(scenario);
    
        return this.reloadAutomationScenarioSequenceUi();

    }
    handleSequenceActionGptFieldUpdate (
        field,
        value
    ) {
        const scenario = this.retrieveSessionStateForScenarioSequence();

        scenario.context = scenario.context || {};

        const fullField = "gpt_scenario_" + field;

        scenario.context[fullField] = value;

        return this.updateSessionStateForScenarioSequence(scenario);
    }
    handleSequenceActionSequenceFieldUpdate (
        field,
        value
    ) {
        const scenario = this.retrieveSessionStateForScenarioSequence();

        scenario[field] = value;

        return this.updateSessionStateForScenarioSequence(scenario);
    }
    
    initAutomationScenarioSequenceListeners () {
        const handler = (e) => {
            const target = e.target;
            const type = target.getAttribute('data-ce_step_action');
            const step = target.closest('[data-ce_step]')
            const noRelatedStep = target.hasAttribute('data-ce_no_step')
            if (!step && !noRelatedStep) {
                console.warn('CE:ERR - Something has gone wrong, no step found with the content listener', { target, type, step });
                return null;
            }
            const stepIndex = noRelatedStep ? 0 : Number(step.getAttribute('data-ce_step'));
            console.log(type, "TYPE IS HERE", step, 'STEP IS HERE', stepIndex)
            switch (type) {
                case 'sms:content': {
                    const val = target.value;
                    this.handleSequenceActionSmsUpdate(
                        val,
                        stepIndex
                    )
                    break;
                };
                case 'email:subject': {
                    const val = target.value;
                    this.handleSequenceActionEmailUpdate(
                        'subject',
                        val,
                        stepIndex
                    );
                    break;
                };
                case 'email:content': {
                    const val = target.value;
                    this.handleSequenceActionEmailUpdate(
                        'content',
                        val,
                        stepIndex
                    );
                    break;
                };
                case 'reminder:unit': {
                    const val = target.value;
                    this.handleSequenceActionReminderUpdate(
                        'unit',
                        val,
                        stepIndex
                    );
                    break;
                };
                case 'reminder:period': {
                    const val = target.value;
                    this.handleSequenceActionReminderUpdate(
                        'period',
                        val,
                        stepIndex
                    );
                    break;
                };
                case 'reminder:force_timeofday': {
                    const val = target.options[target.selectedIndex].value;
                    this.handleSequenceActionReminderConfigUpdate(
                        'force_timeofday',
                        val,
                        stepIndex
                    );
                    break;
                };
                case 'reminder:force_nextbusinessday': {
                    const val = target.checked;
                    this.handleSequenceActionReminderConfigUpdate(
                        'force_business_day',
                        val,
                        stepIndex
                    );
                    break;
                };
                case 'reminder:force_nextbusinesshour': {
                    const val = target.checked;
                    this.handleSequenceActionReminderConfigUpdate(
                        'force_business_hour',
                        val,
                        stepIndex
                    );
                    break;
                };
                case 'on_complete:action': {
                    const val = target.options[target.selectedIndex].value;
                    this.handleSequenceActionOnCompleteUpdate(
                        'scenario',
                        val
                    )
                    break;
                };
                case 'gpt_scenario:update_field': {
                    const val = target.options?.length 
                        ? target.options[target.selectedIndex].value 
                        : target.value;
                    const field = target.getAttribute('data-ce_gpt_scenario_field');
                    this.handleSequenceActionGptFieldUpdate(
                        field,
                        val
                    );
                    break;
                };
                case 'sequence_config:update_field': {
                    const val = target.options?.length 
                        ? target.options[target.selectedIndex].value 
                        : target.value;
                    const field = target.getAttribute('data-ce_sequence_scenario_field');
                    this.handleSequenceActionSequenceFieldUpdate(
                        field,
                        val
                    );
                    break;
                };
                default: {
                    console.warn('CE:WARN - An unsupported sequence content event type was heard:', type)
                    break;
                }
            }
            return null;
        }
    
        document.querySelectorAll('[data-ce_step_action')?.forEach(el => {
            el.addEventListener('keyup', handler);
            
            el.addEventListener('change', handler);
            
            return null;
        })

        return null;
    };
    reloadAutomationScenarioSequenceUi (
        updateAll = false // if true, will also update the title, desc, and convertGPT fields
    ) {
        const scenario = this.retrieveSessionStateForScenarioSequence();

        const steps = scenario.steps || [];
    
        if (!steps.length) {
            console.info('No steps, re-adding the assistant prompt');
            document.querySelector('[data-ce_assist="sequence_gen"]')?.classList.remove('hide');
        }

        const html = steps.map((step, i) => this.renderScenarioSequenceStep(step, i, scenario)).join("");
    
        replaceInnerHtmlByEl(
            document.getElementById('ce_automation_scenario_action_container_inner'),
            html
        )
    
        document.querySelectorAll('.ce_automation_scenario_textarea').forEach(el => {

            const parentEl = el.closest('[data-ce_step]');
            const stepNum = parentEl?.getAttribute('data-ce_step') || null;

            const editor = window.pell?.init({
                element: el,
                onChange: val => {
                    const editorScenario = this.retrieveSessionStateForScenarioSequence();
                    editorScenario.steps[stepNum].actions[editorScenario.steps[stepNum].actions.findIndex(x => x.type === "email")].content.content = val;
                    return this.updateSessionStateForScenarioSequence(editorScenario);
                },
                placeholder: "Write your email here",
                actions: [
                    // 'heading1',
                    // 'heading2',
                    // 'paragraph',
                    'bold',
                    'italic',
                    'underline',
                    'strikethrough',
                    'olist',
                    'ulist',
                    'link',
                    'image'
                  ]
            })
            
            if (stepNum) {
                console.log(steps[stepNum], "NUM");
                const emailVal = steps[stepNum]?.actions?.find(x => x.type === "email")?.content?.content;
                console.log(emailVal)
                editor.content.innerHTML = emailVal;
            }

            console.log(parentEl, "CLSOEST ONE HERE");

            console.log(steps, "STEPS")

            // editor.content.innerHtml = 
        })

        if (updateAll) {
            console.info('is updateall, so updating all');
            [ 'ce_automation_scenario_title', 'ce_automation_scenario_description', 'ce_automation_scenario_persona', 'ce_automation_scenario_company_name', '' ]
            // title & description
            document.getElementById('ce_automation_scenario_title').value = scenario.title;
            document.getElementById('ce_automation_scenario_description').value = scenario.description;
            // convertGPT fields
            document.getElementById('ce_automation_scenario_persona').value = scenario.context?.gpt_scenario_bot_persona;
            document.getElementById('ce_automation_scenario_company_name').value = scenario.context?.gpt_scenario_company_name;
            document.getElementById('ce_automation_scenario_industry').value = scenario.context?.gpt_scenario_industry;
            document.getElementById('ce_automation_scenario_notes').value = scenario.context?.gpt_scenario_notes;
            document.getElementById('ce_automation_scenario_personality').value = scenario.context?.gpt_scenario_bot_personality;
        }

        return this.initAutomationScenarioSequenceListeners();
    };
    removeStepFromAutomationSequenceScenario (
        el,
        index 
    ) {
        console.log(el, "THIS IS EL")

        const scenario = this.retrieveSessionStateForScenarioSequence();
    
        if (Number.isInteger(index) && index >= 0) {
            scenario.steps.splice(index, 1);
        } else {
            const step = el.closest('[data-ce_step]');
    
            if (!step) {
                console.warn('CE:ERR - Something has gone wrong, no step found with the delete step action', { step });
                return null;
            }
            const stepIndex = Number(step.getAttribute('data-ce_step'));
            scenario.steps.splice(stepIndex, 1);
        };
    
        this.updateSessionStateForScenarioSequence(scenario);
    
        return this.reloadAutomationScenarioSequenceUi();
    };
    removeActionFromScenarioStep (
        el,
        index
    ) {

        console.log(el, index, "THIS IS EL")

        const scenario = this.retrieveSessionStateForScenarioSequence();

        const step = el.closest('[data-ce_step]');
    
        if (!step) {
            console.warn('CE:ERR - Something has gone wrong, no step found with the delete step action', { step });
            return null;
        }
        const stepIndex = Number(step.getAttribute('data-ce_step'));

        scenario.steps?.[stepIndex]?.actions?.splice(index, 1);

        this.updateSessionStateForScenarioSequence(scenario);
    
        return this.reloadAutomationScenarioSequenceUi();
    }
    updateOrderOfScenarioSteps (
        position,
        el
    ) {
        console.log(position, el, "THIS");

        const scenario = this.retrieveSessionStateForScenarioSequence();

        const step = el.closest('[data-ce_step]');
    
        if (!step) {
            console.warn('CE:ERR - Something has gone wrong, no step found with the delete step action', { step });
            return null;
        };

        const stepIndex = Number(step.getAttribute('data-ce_step'));

        const newStepIndex = stepIndex + position;
        
        console.info({ stepIndex, newStepIndex});

        if (newStepIndex < 0) {
            console.warn('CE:WARN - Step is already at the start, cannot move up');
            return null;
        };


        scenario.steps.splice(newStepIndex, 0, scenario.steps.splice(stepIndex, 1)[0]);

        this.updateSessionStateForScenarioSequence(scenario);
    
        return this.reloadAutomationScenarioSequenceUi();
    }
    addStepToAutomationSequenceScenario (index = null) {
        console.info('adding step')

        this.addAutomationScenarioStepToLocalContext(index);
        console.log('added this one here')
        this.reloadAutomationScenarioSequenceUi();
    };
    addActionToScenarioSequenceStep (
        el,
        type
    ) {
        const step = el.closest('[data-ce_step]');

        if (!step) {
            console.warn('CE:ERR - Something has gone wrong, no step found with the delete step action', { step });
            return null;
        }
        const stepIndex = Number(step.getAttribute('data-ce_step'));
    
        const scenario = this.retrieveSessionStateForScenarioSequence();
    
        const alreadyHasAction = scenario.steps.find(x => x.type === type) ? true : false;
    
        if (alreadyHasAction) {
            console.warn('CE:ERR Step already has this kind of action, cannot add another:', stepIndex, type);
            return null;
        };
    
        const baseActionMap = {
            sms: {
                type: "sms",
                content: {
                    content: ""
                }
            },
            email: {
                type: "email",
                content: {
                    subject: "",
                    content: ""
                }
            }
        }
    
        scenario.steps[stepIndex].actions.push(baseActionMap[type]);
    
        this.updateSessionStateForScenarioSequence(scenario);
    
        this.reloadAutomationScenarioSequenceUi();
    
        // const newEl = document.querySelector(`[data-ce_step="${stepIndex}"]`);
    
        // window.scroll(0, newEl.offsetTop)
    
        return null;
    }
    async retrieveCompanySequencesForUser () {
        // retrieve custom scenarios for users
        const companiesForScenarios = window.convertEngine.user?.associations?.map(x => x.company_id);
        console.info('CE: Retrieving scenarios for companies:', companiesForScenarios)
        const scenarios = await Promise.all(
            companiesForScenarios.map(
                async(companyId) => window.convertEngine.apiQuery(
                    `company/${companyId}/engine/get-company-scenarios`,
                    'GET'
                )
            )
        )
        
        const filteredScenarios = scenarios
        .filter(x => x.isOk)
        .map(x => x.val?.scenarios || [])
        .flat();
        
        // let filterOptions = []

        // filteredScenarios.forEach(option => {
        //     const l = optionsSelect.options?.length
        //     const optionEl = new Option(option.title, option.action);
        //     optionEl.setAttribute('data-config', JSON.stringify(option))
        //     optionsSelect.options[l] = optionEl;
        //     return null;
        // });

        console.log(filteredScenarios, "SCENARIOS HERE")
        // store these in window state
        this.state.scenario_options = filteredScenarios;

        return null;
    }
    prefillGptAssistantFields () {
        const scenario = this.retrieveSessionStateForScenarioSequence();

        // persona
        document.getElementById('ce_automation_scenario_persona').value = scenario.context?.gpt_scenario_bot_persona || "";
        // personality
        document.getElementById('ce_automation_scenario_personality').value = scenario.context?.gpt_scenario_bot_personality || "";
        // company name
        document.getElementById('ce_automation_scenario_company_name').value = scenario.context?.gpt_scenario_company_name || "";
        // industry
        document.getElementById('ce_automation_scenario_industry').value = scenario.context?.gpt_scenario_industry || "";
        // notes
        document.getElementById('ce_automation_scenario_notes').value = scenario.context?.gpt_scenario_notes || "";

        return null;
    }
    prefillSequenceConfigFields () {
        const scenario = this.retrieveSessionStateForScenarioSequence();

        // title
        document.getElementById('ce_automation_scenario_title').value = scenario.title;
        // description
        document.getElementById('ce_automation_scenario_description').value = scenario.description;
    }
    async initAutomationScenarioSequence () {
        console.log('scenario sequence')

        // retrieving company-specific scenarios
        await this.retrieveCompanySequencesForUser();

        // load or create new scenario
        const scenario = this.retrieveSessionStateForScenarioSequence();
        // update the local state
        this.updateSessionStateForScenarioSequence(scenario);
        // reload the UI
        this.reloadAutomationScenarioSequenceUi();
        // if there is already 
        if (scenario.steps?.length) {
            console.info('Steps already found on this scenario, removing container')
            const assistContainer = document.querySelector('[data-ce_assist="sequence_gen"]');
            this.dismissAutomationAssistContainer(assistContainer);
        };
    
        // pre-load the fields (if there is anything to pre-load)
        this.prefillGptAssistantFields();
        this.prefillSequenceConfigFields();

        // prefill companies 
        const companies = window.convertEngine.user?.associations?.filter(x => x.company_id !== "*")?.map(x => x.company_id) || [];
        const companySelectEl = document.getElementById('ce_automation_scenario-sequence_company_id');
        companies?.forEach(company => companySelectEl?.add(new Option(company, company)));

        return null;
    }
    updateScenarioSequenceFinalStepConfig (
        el
    ) {
        const optionVal = el.options[el.selectedIndex].value;

        console.log(el, optionVal, optionVal.value, optionVal.val, el.options, "EL IS HERE");
    
        const scenario = this.retrieveSessionStateForScenarioSequence();
    
        scenario.sequence_config.on_complete.type = optionVal;
    
        this.updateSessionStateForScenarioSequence(scenario);
    
        return this.reloadAutomationScenarioSequenceUi();
    }
    dismissAutomationAssistContainer (
        el,
        reverse = false
    ) {
        this;
        const parentEl = el.closest('.ce_convert_assist_container');

        if (!parentEl) {
            console.warn('CE:WARN - Attempting to dismiss automation assistant, but no parent container found', el);
            return null;
        }
    
        console.log(parentEl, "EL");
    
        const buttons = parentEl.querySelector('.ce_automation_scenario_action_container_btns');

    
        if (reverse) {
            buttons?.classList.remove('hide')
        } else {
            buttons?.classList.add('hide')
        }
    
        return null;
    }
    async generateSequenceWithAssistant () {

        const scenario = this.retrieveSessionStateForScenarioSequence();

        console.log(scenario, "SECNARIO HERE")

        // const companyId = window.convertEngine.user?.associations?.[0]?.company_id;

        const assistContainer = document.querySelector('[data-ce_assist="sequence_gen"]');
        this.dismissAutomationAssistContainer(assistContainer);

        window.convertEngine.handleActionButtonState(document.body, 'automation_scenario', 'start');

        const innerHtml = `<div class="ce_automation_scenario_step_inner p-3 bg-ai shadow-sm mb-3">
            <div class="col-12 d-block">
                <div class="text-align-center my-3">
                    <span class="fs-2">Working... ⏳</span>
                </div>
                <div class="text-align-center mb-2">
                    <span class="fs--1">ConvertGPT is now turning your brief into a sequence!</span>
                </div>
                <div class="text-align-center mb-3">
                    <span class="fs--1">ℹ️ This process can take up to 60 seconds to complete.</span>
                </div>
                
            </div>
        </div>`;

        replaceInnerHtmlByEl(
            document.getElementById('ce_automation_scenario_action_container_inner'),
            innerHtml
        );

        const sequenceGenerateRes = await window.convertEngine.apiLongQuery(
            `https://6kajgh4cweps2flo5kpod62xsu0fbbyl.lambda-url.ap-southeast-2.on.aws/`,
            'POST',
            scenario,
            false,
            true
        );
    
        console.log(sequenceGenerateRes, "CREATE RES HERE")
    
        if (sequenceGenerateRes.isErr) {
            console.warn('CE:ERR - An error occured when attempting to create new Scenario:', sequenceGenerateRes);
            window.convertEngine.createToastAlert(
                '🛑 Scenario could not be created', 
                sequenceGenerateRes.val,
                "danger")
            this.dismissAutomationAssistContainer(assistContainer, true);
            window.convertEngine.handleActionButtonState(document.body, 'automation_scenario', 'fail')
            replaceInnerHtmlByEl(
                document.getElementById('ce_automation_scenario_action_container_inner'),
                ''
            );
        } else {

            const steps = sequenceGenerateRes.val?.res;

            console.log(steps, "STEPS ARE HERE")

            // scenario.s

            window.convertEngine.createToastAlert(
                '😁 Success!', 
                'Your new automation was started successfully! 🤖'
            )
            window.convertEngine.handleActionButtonState(document.body, 'automation_scenario', 'success')

            scenario.steps = steps;

            this.updateSessionStateForScenarioSequence(scenario);
    
            return this.reloadAutomationScenarioSequenceUi();
        }

        return null;
    }
    async createAutomationScenarioSequence(
        el
    ) {
            // create the request object

        const sequenceConfig = this.state?.ce_scenario_sequence;

        // TODO - Add verbose error handline in here
        
        const companyId = document.getElementById('ce_automation_scenario-sequence_company_id')?.value;

        let request = {
            company_id: companyId,
            type: "sequence",
            fields: sequenceConfig
        };


        console.log(request, "REQUEST")

        window.convertEngine.handleActionButtonState(el, 'automation_scenario', 'start');

        const scenarioCreateRes = await window.convertEngine.apiQuery(
            `company/${companyId}/engine/create-automation-scenarios`,
            'POST',
            request
        );

        console.log(scenarioCreateRes, "CREATE RES HERE")

        if (scenarioCreateRes.isErr) {
            console.warn('CE:ERR - An error occured when attempting to create new Sequence:', scenarioCreateRes);
            window.convertEngine.createToastAlert(
                '🛑 Sequence could not be created', 
                scenarioCreateRes.val,
                "danger")
            window.convertEngine.handleActionButtonState(el, 'automation_scenario', 'fail')
        } else {
            window.convertEngine.createToastAlert(
                '😁 Success!', 
                'Your new sequence was created successfully! 🤖'
            )
            window.convertEngine.handleActionButtonState(el, 'automation_scenario', 'success')
        };

        return null;
    }
    async populateAutomationScenariosForList(
    ) {

        console.info('Populating list of sequences for user..')
        await this.retrieveCompanySequencesForUser();
        console.info('Sequences loaded:', this.state)

        const scenarioItem = option => `<a class="btn btn-light w-100 text-start mb-2" href="/app/automations/edit_scenario/sequence.html?seq=${option.action}" target="_blank">
            <div class="d-block flex-between-center rounded-3 p-3">
            <h6 class="mb-0 fs-0 d-block"><span class="fs--1 fw-bold me-3 fas fa-circle danger"></span> ${option.title}</h6>
            ${option.description ?  `<p class="mb-0 mt-1 fs--1 text-500">${option.description}</p>`: ''}
            </div>
        </a>`;

        const scenarioElements = this.state?.scenario_options?.filter(opt => opt.title?.length)?.map(option => scenarioItem(option)).join("");

        console.log(scenarioElements, "ELEMENTS");

        return replaceInnerHtmlByEl(
            document.getElementById('ce_automation_scenario_my_scenario_list'),
            scenarioElements
        );

    }
    async loadAutomationScenario(
    ) {

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        const scenario = params.seq

        console.log(scenario, this, "SEQ IS HERE");

        // retrieving company-specific scenarios
        await this.retrieveCompanySequencesForUser();

        if (!scenario) {
            window.convertEngine.createToastAlert(
                '⚠️ Sequence could not be retrieved',
                'An error occured retrieving the sequence, please try again later.',
                'danger'
            );
            return null;
        }

        const companyId = window.convertEngine?.user?.associations?.[0]?.company_id

        let request = {
            id: scenario,
        };

        const scenarioGetRes = await window.convertEngine.apiQuery(
            `company/${companyId}/engine/get-scenario`,
            'POST',
            request
        );

        console.log(scenarioGetRes, "CREATE RES HERE")

        if (scenarioGetRes.isErr) {
            console.warn('CE:ERR - An error occured when attempting to retrieve Scenario:', scenarioGetRes);
            window.convertEngine.createToastAlert(
                '🛑 Sequence could not be created', 
                scenarioGetRes.val,
                "danger")
        } else {
            window.convertEngine.createToastAlert(
                '😁 Success!', 
                'Scenario loaded successfully'
            )
        };

        const retrievedScenario = scenarioGetRes.val?.scenario;

        console.log(retrievedScenario, "RETRIEVED ONE HERE")

        this.updateSessionStateForScenarioSequence(retrievedScenario.sequence_config);
    
        // also update another state with the overarching sequence object

        this.state.ce_scenario = retrievedScenario;

        return this.reloadAutomationScenarioSequenceUi(true);

    }
};SequenceScenarioBuilder;

const retrieveSequenceScenarioBuilder = () => {
    const builder = window.convertEngine.state.sequence_builder || new SequenceScenarioBuilder();

    if (!window.convertEngine.state.sequence_builder) {
        window.convertEngine.state.sequence_builder = builder;
    };

    return builder
};retrieveSequenceScenarioBuilder;